.Menu {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 80%;
    z-index: var(--zIndexMain);
    pointer-events: all;
}

.Menu__container {
  padding: 1em;
  border-radius: 2em;
  background-color: var(--colorWhite);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(68,68,68,0.3);
  -moz-box-shadow: 0px 0px 8px 1px rgba(68,68,68,0.3);
  box-shadow: 0px 0px 8px 1px rgba(68,68,68,0.3);
}

.Menu__container > * {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.Menu ul {
    list-style: none;
    display: inline-block;
    margin-left: 0.5em;
    padding-left: 0;
    margin-top: 0;
}

.Menu ul li {
    display: inline-block;
    margin: 0 1em;
    cursor: pointer;
    vertical-align: middle;
}

.Menu ul li:last-child {
  margin-right: 0;
}

.Menu__localize-me {
    padding-right: 1.4em;
    background: transparent url('./assets/_svg/_ui-interface/icon-location.svg') no-repeat;
    background-position: center right;
    background-size: auto 100%;
}

.Menu__localize-me--localized {
  color: var(--colorLocation);
  background-image: url('./assets/_svg/_ui-interface/_map/icon-geolocation-on-orange.svg');
}

.Menu__localize-me:hover {
  background-image: url('./assets/_svg/_ui-interface/_map/icon-geolocation-on-blue.svg');
}

.Menu__share-label {
  color: var(--colorAsphalt);
}

.Menu__share-button:hover svg * {
  fill: var(--colorOcean);
}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {
  .Menu__share-label, .Menu ul li.Menu__share-button {
    display: none;
  }
}

/* Style generated with https://sharingbuttons.io/ */
.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
}

.resp-sharing-button {
  border-radius: 5px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif
}

.resp-sharing-button__icon svg {
  width: 1.5em;
  vertical-align: top
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: var(--textBlack);
  fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: var(--textBlack);
  stroke: none
}