.SearchField {
    position: relative;
}

.SearchField input {
    width: 100%;
    height: var(--inputHeight);
}

.SearchField input:focus {
    outline: none;
}

.SearchField__button {
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-38%);
    background: none;
    border: none;
    cursor: pointer;
}

.SearchField--empty .SearchField__reset-image,
.SearchField--has-content .SearchField__search-image {
    display: none;
}

svg.SearchField__reset-image * {
    fill: var(--colorWarning);
}