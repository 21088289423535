
.FooterContactBar {
    background-color: var(--colorAlpine);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    z-index: var(--zIndexStickies);
}

.FooterContactBar h2 {
    margin: 0;
    margin-bottom: 0.3em;
}

.FooterContactBar img {
    height: 100%;
    margin-right: 0.5em;
}

.FooterContactBar__vous-avez-un-doute-container {
    display: flex;
    align-items: flex-end;
}

.FooterContactBar__vous-avez-un-doute {
    font-size: 80%;
}

.FooterContactBar__phone-container {
    margin-left: 0.5em;
}

.FooterContactBar__phone {
    font-size: 160%;
    font-weight: bold;
    white-space: nowrap;
}

.FooterContactBar__appel-gratuit {
    color: var(--colorAsphalt);
    font-size: 70%;
    font-style: italic;
}

/* TABLET_WIDTH_UPPER */
@media (max-width: 1280px) {

    .FooterContactBar__vous-avez-un-doute-container {
        display: initial;
        align-items: initial;
    }

    .FooterContactBar__phone-container {
        margin-left: 0;
    }
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .FooterContactBar {
        display: block;
        text-align: center;
    }

    .FooterContactBar {
        width: 100%;
    }
}