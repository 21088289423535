.Map__map-container {
    position: relative;
}

.Map__footer-container {
    position: absolute;
    width: 100%;
    /* to leave space for tiles attribution */
    bottom: 20px;
    left: 0;
    z-index: var(--zIndexAboveMap);
    padding-left: var(--pageHorizontalPadding);
    padding-right: var(--pageHorizontalPadding);
}

.Map__legend {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--zIndexAboveMap);
    background-color: var(--colorWhite);
    border-radius: 2em;
    font-size: 70%;
    margin-left: var(--pageHorizontalPadding);
    margin-top: 1em;
}

.my-location-tooltip {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.my-location-tooltip::before {
    border-bottom-color: transparent;
}

.leaflet-control-container .leaflet-control-attribution {
    font-size: 8px;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .Map__footer-container {
        margin: 0;
        margin-bottom: 1em;
        padding-left: var(--pageHorizontalInnerPadding);
        padding-right: var(--pageHorizontalInnerPadding);
    }

    .Map__legend {
        margin-left: var(--pageHorizontalInnerPadding);
    }
}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {
    .MapInfoBox__info-box {
        border-radius: 0;
    }

    .Map__footer-container {
        font-size: 80%;
    }

    .Map__legend {
        font-size: 50%;
    }
}