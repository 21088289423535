.MapInfoBox__info-box {
    padding: 1em;
    color: var(--textWhite);
    background-color: var(--colorNightBlue);
    margin-bottom: 1em;
    border-radius: 1em;
    /* to allow positioning of close button */
    position: relative;
}

.MapInfoBox__expand-collapse {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
}

.MapInfoBox__info-box--error {
    background-color: var(--colorWarning);
}

.MapInfoBox__info1,
.MapInfoBox__info2,
.MapInfoBox__info3,
.MapInfoBox__info4 {
    padding: 8px 0;
}

.MapInfoBox--collapsed .MapInfoBox__info1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.MapInfoBox--collapsed .MapInfoBox__info2,
.MapInfoBox--collapsed .MapInfoBox__info3,
.MapInfoBox--collapsed .MapInfoBox__info4 {
    display: none;
}

.MapInfoBox__info1 {
    font-weight: bold;
    font-size: 20px;
}

.MapInfoBox__info2 {
    font-weight: bold;
    font-size: 14px;
}

.MapInfoBox__info3 {
    font-size: 14px;
}

.MapInfoBox__info4 {
    font-size: 12px;
}

.MapInfoBox__info-icon {
    display: inline-block;
    vertical-align: middle;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .MapInfoBox__info-box {
        margin-bottom: 0;
    }
}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {
    .MapInfoBox__expand-collapse {
        display: none;
    }
}