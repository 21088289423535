.SolutionPage {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* Allow absolute positioning of backgrounds */
    position: relative;
    background-color: var(--colorWhite);
}

.SolutionPage .Header .Header__background {
    opacity: 1;
}

.SolutionPage__body {
    /* Adapt its size to take up whats left by header and footer */
    flex-grow: 1;
    flex-shrink: 100;
    overflow: hidden;
    display: flex;
    z-index: var(--zIndexMain);
}

.SolutionPage__left, .SolutionPage__right {
    width: 50%;
    height: auto;
    padding-top: var(--headerHeight);
}

.SolutionPage__left > *, .SolutionPage__right > * {
    margin-left: var(--pageHorizontalPadding);
    margin-right: var(--pageHorizontalPadding);
}

.SolutionPage__left {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.SolutionPage__left .SolutionPage__title {
    /* to allow animation for image */
    padding-right: 0;
}

.SolutionPage__right {
    background-color: var(--colorAlpine);
    /* to allow absolute positioning of elements inside */
    position: relative;
    /* the map on the right side is sized dynamically and should fill in the height */
    overflow: hidden;
}

.SolutionPage__title {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    /* Overwrite the default margin so the image can swipe in 
    from the right */
    margin-right: 0;
    /* Overwrite the default margin so we can see the underlined word */
    margin-left: 0;
    padding-left: var(--pageHorizontalPadding);
    overflow: hidden;
}

.SolutionPage__title img {
    max-width: 40%;
}

.SolutionPage__title h1 {
    max-width: 60%;
}

.SolutionPage__more-info {
    display: inline-block;
    margin: 2em 0;
}

.SolutionPage__info {
    flex-grow: 1;
    overflow: auto;
    padding-bottom: 1em;
}

.SolutionPage__info > div:last-child {
    /* Add a margin when last element of the infos */
    margin-bottom: 1em;
}

.SolutionPage__to-home {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: 80%;
}

.SolutionPage__did-you-know h2 {
    color: var(--colorOcean);
}

.SolutionPage .FooterSearchBar,
.SolutionPage .FooterContactBar,
.SolutionPage .Footer__search-contact {
    z-index: var(--zIndexStickies);
}


/* PHABLET_WIDTH_UPPER */
@media (min-width: 1025px) {
    .SolutionPage .MapShapes, .SolutionPage .MapPins {
        /* Remove margins when in desktop layout */
        margin: 0;
    }
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .SolutionPage {
        overflow: auto;
        display: block;
    }

    .SolutionPage__left, .SolutionPage__right {
        width: auto;
        background-color: var(--colorWhite);
    }

    .SolutionPage__right {
        padding-top: 0;
    }

    .SolutionPage__body {
        display: block;
        padding-bottom: 3em;
    }

    .SolutionPage .MapShapes, .SolutionPage .MapPins {
        border-radius: var(--borderRadiusBoxes);
        overflow: hidden;
    }
}

/* ANIMATION */
.SolutionPage__title img {
    position: relative;
    right: -30%;
    transition-property: right;
    transition-duration: 1s;
    transition-timing-function: var(--transitionFunction);
}

.SolutionPage--animation-start .SolutionPage__title img {
    right: var(--pageHorizontalPadding);
}


/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .SolutionPage--animation-start .SolutionPage__title img {
        right: -1em;
    }
}