/* Setup animations */
.Home .Header, 
.Home h1, 
.Home .SolutionIcon {
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: var(--transitionFunction);
}
.Home--animation-start .Header, 
.Home--animation-start h1, 
.Home--animation-start .SolutionIcon {
    opacity: 1;
}

.Home--normal-height .Footer__search-contact,
.Home--normal-height .Footer__search-contact--hidden,
.Home--normal-height .FooterSearchBar,
.Home--normal-height .FooterContactBar__vous-avez-un-doute-container {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition-property: max-height, padding-top, padding-bottom;
    transition-duration: 0.5s;
    transition-timing-function: var(--transitionFunction);
}
.FooterContactBar__vous-avez-un-doute-container {
    /* to hide the overflowing content */
    overflow: hidden;
}
.Home--animation-start .Footer__search-contact,
.Home--animation-start .Footer__search-contact--hidden,
.Home--animation-start .FooterSearchBar,
.Home--animation-start .FooterContactBar__vous-avez-un-doute-container {
    /* This is just a random value, trick to animate the height from 0 to auto :
    https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css */
    max-height: 50vh;
}
.Home--animation-start .FooterSearchBar {
    padding-top: var(--searchContactPaddingTop);
    padding-bottom: var(--searchContactPaddingBottom);
}

/* PHABLET_WIDTH_UPPER */
@media (min-width: 1024px) {
    .Home .FooterSearchBar {
        transition-property: none;
    }

}
/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .Home .Footer__search-contact,
    .Home .Footer__search-contact--hidden {
        transition-property: none;
    }
    .Home--animation-start .FooterSearchBar {
        padding-top: var(--searchContactPaddingTopMobile);
        padding-bottom: var(--searchContactPaddingBottomMobile);
    }
}

.Home .FooterContactBar img {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.6s;
    transition-timing-function: var(--transitionFunction);
}
.Home--animation-start .FooterContactBar img {
    opacity: 1;
}

/* Animation times */    
.Home .Header, .Home h1 {
    transition-delay: 0;
    transition-duration: 0.8s;
}
.Home .UnderlinedWord__line {
    transition-delay: 1s;
}
.Home .SolutionIcon {
    transition-duration: 0.8s;
}
.Home .SolutionIcon:nth-child(1) { transition-delay: 0.7s; }
.Home .SolutionIcon:nth-child(2) { transition-delay: 0.8s; }
.Home .SolutionIcon:nth-child(3) { transition-delay: 0.9s; }
.Home .SolutionIcon:nth-child(4) { transition-delay: 1.0s; }
.Home .SolutionIcon:nth-child(5) { transition-delay: 1.1s; }
.Home .SolutionIcon:nth-child(6) { transition-delay: 1.2s; }
.Home .SolutionIcon:nth-child(7) { transition-delay: 1.3s; }
.Home .SolutionIcon:nth-child(8) { transition-delay: 1.4s; }
.Home .SolutionIcon:nth-child(9) { transition-delay: 1.5s; }
.Home .SolutionIcon:nth-child(10) { transition-delay: 1.6s; }
.Home .SolutionIcon:nth-child(11) { transition-delay: 1.7s; }
.Home--normal-height .Footer__search-contact, 
.Home--normal-height .Footer__search-contact--hidden, 
.Home--normal-height .FooterSearchBar,
.Home--normal-height .Footer__search-contact .FooterContactBar__vous-avez-un-doute-container 
    { transition-delay: 1.7s; }

.Home .FooterContactBar img { transition-delay: 1.8s; }
