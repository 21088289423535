
.MapAddressSearch {
    flex-grow: 1;
    padding-bottom: calc(var(--verticalPadding) * 3);
    /* to allow absolute positioning of search results */
    position: relative;
}

.MapAddressSearch__results {
    position: absolute;
    /* +1 because must be above map legend */
    z-index: calc(var(--zIndexAboveMap) + 1);
    top: 80%;
    left: 0;
    background-color: var(--colorNightBlue);
    color: var(--colorWhite);
    width: 100%;
    border-radius: 1em;
    padding: 1em;
}

.MapAddressSearch__results-item {
    margin: 0.5em 0;
    cursor: pointer;
}

.MapAddressSearch__results-item:hover {
    color: var(--colorLocation);
}