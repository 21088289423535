.MapFooter {
    display: flex;
    justify-content: space-between;
}

.MapFooter__see-data svg,
.MapFooter__see-data span {
    display: inline-block;
    vertical-align: middle;
}

.MapFooter__see-data:hover svg * {
    fill: var(--colorWhite);
}
