.FooterSearchBar, .FooterContactBar {
    width: 50%;
    /* This is important to limit the width of the layout. 
    Also note that flex container must have justify content center */
    max-width: calc(var(--appMaxWidth) / 2);
    padding: 0 var(--pageHorizontalPadding);
    padding-top: var(--searchContactPaddingTop);
    padding-bottom: var(--searchContactPaddingBottom);
}


/* NARROW_SCREEN_UPPER_2 */
@media (max-height: 580px) {
    .FooterSearchBar, .FooterContactBar {
        font-size: 14px;
    }
}