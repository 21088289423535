.SolutionBox {
    margin-top: 1em;
    margin-bottom: 1em;
    background-color: var(--colorWhite);
    border-radius: var(--borderRadiusBoxes);
    cursor: pointer;
}

.SolutionBox:last-child {
    margin-bottom: 2em;
}

.SolutionBox__surtitle {
    color: var(--textBlue);
    font-weight: bold;
    font-size: 85%;
}

.SolutionBox__title {
    font-size: 120%;
    font-weight: bold;
    margin: 0.3em 0;
}

.SolutionBox__header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.SolutionBox__body {
    padding: 1em;
}

.SolutionBox__footer {
    color: var(--textWhite);
    font-weight: bold;
    font-size: 85%;
    text-align: right;
    background-color: var(--colorOcean);
    padding: 1em;
    border-bottom-left-radius: var(--borderRadiusBoxes);
    border-bottom-right-radius: var(--borderRadiusBoxes);
}

.SolutionBox__footer:hover {
    background-color: var(--colorNightBlue);
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .SolutionBox {
        background-color: var(--colorAlpine);
    }

    .SolutionBox__header img {
        display: none;
    }

}