.About h2 {
  color: var(--colorOcean);
  font-size: 110%;
}

.About .SolutionPage__right {
    /* Because we want to be able to scroll through the solutions */
    overflow: auto;
}

.About .SolutionPage__info a {
    font-weight: bold;
    border-bottom: 2px var(--colorOcean) solid;
}

.About .SolutionPage__info a:hover {
    border-color: var(--textBlack);
    color: var(--colorOceanLight);
}