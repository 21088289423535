/* trick to allow space for the fixed footer to land
 at the bottom of the page */
.Footer__search-contact.Footer__search-contact--hidden {
    visibility: hidden;
    position: initial;
}

.Footer__search-contact {
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: var(--zIndexStickies);
    width: 100%;
    justify-content: center;
    /* Make mouse events go through */
    pointer-events: none;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .Footer__search-contact {
        display: block;
        position: initial;
        padding: 0;
    }

    .Footer__search-contact.Footer__search-contact--hidden .FooterSearchBar {
        position: initial;
    }

    .Footer__search-contact.Footer__search-contact--hidden .FooterContactBar {
        display: none;
    }

    .Footer__search-contact .FooterSearchBar {
        position: fixed;
        bottom: 0;
    }

    .Footer__search-contact .FooterContactBar {
        position: initial;
        display: block;
        text-align: center;
    }

    .Footer__search-contact .FooterSearchBar, 
    .Footer__search-contact .FooterContactBar {
        width: 100%;
        max-width: initial;
    }
}