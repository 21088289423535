.App {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  max-width: var(--appMaxWidth);
  margin: auto;
  background-color: var(--colorAlpineLight);
  scrollbar-color: var(--colorOcean) var(--colorWhite);
  scrollbar-width: var(--scrollbarWidth);
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* TABLET_WIDTH_UPPER */
@media (min-width: 1280px) {
  .App > * {
      left: calc((100vw - 1280px) / 2);
  }
}