.Header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: var(--zIndexStickies);
    height: var(--headerHeight);
    pointer-events: none;
}

.Header__container {
    max-width: var(--appMaxWidth);
    margin: auto;
    padding: 1.8em;
    padding-bottom: 0;
    padding-right: 2.5em;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Header__logo-container {
    display: inline-block;
    height: 100%;
    pointer-events: all;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .Header {
        height: var(--headerMobileHeight);
    }

    .Header__logo-container {
        background-color: var(--colorWhite);
        border-bottom-right-radius: 50%;
    }

}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {
    .Header__container {
        padding: 0.7em;
    }
}