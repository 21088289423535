.FooterSearchBar {
    display: inline-block;
    /* to allow the absolute positioning of the search results */
    position: relative;
    background-color: var(--colorOcean);
    /* make sure that mouse events are captured */
    pointer-events: all;
    z-index: var(--zIndexStickies);
}

.FooterSearchBar label {
    color: var(--textWhite);
    font-weight: bold;
    margin-top: 0.3em;
    margin-bottom: 0.7em;
    display: inline-block;
}

.FooterSearchBar__search-field input {
    border-radius: 1.5em;
    border: 2px solid var(--colorOcean);
    padding-left: 1em;
}

.FooterSearchBar__search-field input:hover,
.FooterSearchBar__search-field input:focus {
    border-color: var(--colorNightBlue);
}

.FooterSearchBar--no-result .FooterSearchBar__search-field input {
    border-color: var(--colorWarning);
}

.FooterSearchBar__results {
    position: absolute;
    width: 100%;
    bottom: 100%;
    left: 0;
    height: calc(100vh - 100%);
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: var(--colorOcean) no-repeat;
    background-position: center;
    background-size: 100% auto;
    color: var(--textWhite);
    /* we leave space for the border-radius of teh search results when hovered */
    padding-left: calc(var(--pageHorizontalPadding) - 1em);
    padding-right: var(--pageHorizontalPadding);
    scrollbar-color: var(--colorOcean) var(--colorAlpine);
    scrollbar-width: var(--scrollbarWidth);
    /* make sure that mouse events are captured */
    pointer-events: all;
}

.FooterSearchBar--no-result .FooterSearchBar__results {
    background-image: url('../assets/_svg/_icons/_others/icon-no_result.svg');
}

.FooterSearchBar__results-item {
    cursor: pointer;
    padding: 0.3em 0;
    border-radius: 1em;
    padding-left: 1em;
    font-size: 32px;
}

.FooterSearchBar__results-item:hover {
    background-color: var(--colorOceanLight);
    color: var(--colorNightBlue);
}

.FooterSearchBar__results-item:first-child {
    /* There's a bug with flex justify end and scrolbars.
    This is a fix to push items to the end : https://stackoverflow.com/questions/36130760/use-justify-content-flex-end-and-to-have-vertical-scrollbar */
    margin-top: auto;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .FooterSearchBar {
        display: block;
        position: initial;
    }

    .FooterSearchBar label {
        font-size: 90%;
    }

}

/* NARROW_SCREEN_UPPER_2 */
@media (max-height: 580px) {
    input {
        height: 34px;
    }
}