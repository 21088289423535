.UnderlinedWord {
    display: inline-block;
    position: relative;
    /* So that the line is not overflowed */
    margin-bottom: 0.5em;
}

.UnderlinedWord__shaper {
    visibility: hidden;
}

.UnderlinedWord__text {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: var(--zIndexMain);
}

.UnderlinedWord__line {
    display: inline-block;
    width: 0.5em;
    height: 0.45em;
    border-radius: 0.5em;
    position: absolute;
    bottom: 0;
    left: -5%;
    background-color: var(--colorCitron);
    transition-property: width;
    transition-duration: 1.2s;
    transition-timing-function: var(--transitionFunction);
}

.UnderlinedWord__line--animation-start {
    width: 110%;
}