.MapZoomControls__plus_minus {
    display: inline-block;
    border-radius: 1em;
    background-color: var(--colorWhite);
}

.MapZoomControls__plus_minus,
.MapZoomControls__center {
    border: 3px solid var(--colorWhite);
    height: var(--buttonHeight);
}

.MapZoomControls__plus, 
.MapZoomControls__minus,
.MapZoomControls__center {
    display: inline-block;
    border-radius: 1em;
    cursor: pointer;
}

.MapZoomControls__plus, 
.MapZoomControls__minus {
    height: 100%;
}

.MapZoomControls__center {
    vertical-align: top;
    margin-right: 0.5em;
}

.MapZoomControls__minus {
    margin-right: 0.2em;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .MapZoomControls__center {
        display: none;
    }
}