.SolutionIcon {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    /* to allow the title to be positioned absolutely.
    This is needed because otherwise it will appear below the images
    with are absolute positioned  */
    position: relative;
    margin: 2em 1em;
    width: 8em;
}

.SolutionIcon__image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 125%;
    transform: translate(-50%, -50%);
}

.SolutionIcon__animation {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    transition-property: width;
    transition-duration: 0.5s;
    transition-timing-function: var(--transitionFunction);
}

.SolutionIcon--desktop:hover .SolutionIcon__animation {
    width: 118%;
}

.SolutionIcon__image-container {
    width: 100%;
    position: relative;
    display: inline-block;
}

.SolutionIcon__image-container circle {
    fill: var(--colorAlpine);
}

.SolutionIcon__title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* allow to place the text hanging to the bottom of the main container */
    transform: translateY(100%);
    font-weight: bold;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .SolutionIcon {
        width: 100%;
        width: 7em;
    }
}

/* NARROW_SCREEN_UPPER_1 */
@media (max-height: 720px) {
    .SolutionIcon {
        margin-top: 1.7em;
        margin-bottom: 2.7em;
    }
    .SolutionIcon__image-container {
        width: 80%;
    }
}

/* NARROW_SCREEN_UPPER_2 */
@media (max-height: 580px) {
    .SolutionIcon {
        margin-top: 1.2em;
        margin-bottom: 1.5em;
    }
    .SolutionIcon__image-container {
        width: 60%;
    }
    .SolutionIcon__title {
        font-size: 14px;
    }
}