/* <div class="loader">Loading...</div> */

.Loader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  /* prevent from being overriden by App */
  left: 0 !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Loader__spinner {
  position: relative;
  bottom: 3em;
}

/* Obtained from designer */

.Loader__spinner,
.Loader__spinner:before,
.Loader__spinner:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: animLoad 1.8s infinite ease-in-out;
  animation: animLoad 1.8s infinite ease-in-out;
}
.Loader__spinner {
  color: #018a39;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.Loader__spinner:before,
.Loader__spinner:after {
  content: '';
  position: absolute;
  top: 0;
}
.Loader__spinner:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  color:#fec916;
}
.Loader__spinner:after {
  left: 3.5em;
  color:#1c6bb3;
}
@-webkit-keyframes animLoad {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes animLoad {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}