@import url('https://rsms.me/inter/inter.css');
html, input { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html, input { font-family: 'Inter var', sans-serif; }
}

:root {
  --colorAlpine: #e5e8eb;
  --colorAlpineLight: #f6f6f6;
  --colorNightBlue: #0f2942;
  --colorWarning: #f7594d;
  --colorOcean: #1c6bb3;
  --colorWhite: white;
  --colorAsphalt: #818e9c;
  --colorCitron: #fec916;
  --colorGardenLight: #68b496;
  --colorOceanLight: #6daedd;
  --colorLocation: #ff801f;

  --textBlack: var(--colorNightBlue);
  --textWhite: var(--colorWhite);
  --textBlue: var(--colorOcean);

  --appMaxWidth: 1280px;
  --headerHeight: 6.5em;
  --headerMobileHeight: 5em;
  --transitionFunction: cubic-bezier(.40, 0, 0, 1);

  --scrollbarWidth: 6px;

  --searchContactPaddingTop: 2em;
  --searchContactPaddingBottom: 2em;
  --searchContactPaddingTopMobile: 0.7em;
  --searchContactPaddingBottomMobile: 1.1em;
  --pageHorizontalPadding: 2.5em;
  --pageHorizontalInnerPadding: 1.5em;
  --inputHeight: 40px;
  --buttonHeight: 2.2em;
  --borderRadiusBoxes: 0.4em;

  --zIndexBg: 1;
  --zIndexMain: 5;
  --zIndexAboveMap: 1000;
  --zIndexStickies: 1010;
}

::-webkit-scrollbar {
  width: var(--scrollbarWidth);
}
::-webkit-scrollbar-thumb {
  background: var(--colorOcean);
  border-radius: var(--scrollbarWidth);
  -webkit-border-radius: var(--scrollbarWidth);
}
::-webkit-scrollbar-track {
  background: var(--colorAlpine);
  border-radius: var(--scrollbarWidth);
  -webkit-border-radius: var(--scrollbarWidth);
}
* {
  scrollbar-color: var(--colorOcean) var(--colorWhite);
  scrollbar-width: var(--scrollbarWidth);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--colorAlpineLight);
  color: var(--textBlack);
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: var(--textBlack);
}

/* (BAD) FIX for safari zooming to input fields,
https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone */
input {
  font-size: 16px;
}

.button {
  height: var(--buttonHeight);
  border-radius: var(--buttonHeight);
  padding: 0.5em 1em;
  border: none;
  cursor: pointer;
  background-color: var(--colorAlpine);
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
}

.button--blue {
  background-color: var(--colorOcean);
  color: var(--textWhite);
}

.button--blue:hover {
  background-color: var(--colorNightBlue);
}

.button--blue.button--dark-bg:hover {
  background-color: var(--colorWhite);
  color: var(--colorNightBlue);
}

.button--white {
  background-color: var(--colorWhite);
  color: var(--colorOcean);
}

.button--white:hover {
  background-color: var(--colorNightBlue);
  color: var(--colorWhite);
}

.link {
  font-weight: bold;
}

.link--menu:hover {
  color: var(--colorOcean);
}