@import url(https://rsms.me/inter/inter.css);
html, input { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html, input { font-family: 'Inter var', sans-serif; }
}

:root {
  --colorAlpine: #e5e8eb;
  --colorAlpineLight: #f6f6f6;
  --colorNightBlue: #0f2942;
  --colorWarning: #f7594d;
  --colorOcean: #1c6bb3;
  --colorWhite: white;
  --colorAsphalt: #818e9c;
  --colorCitron: #fec916;
  --colorGardenLight: #68b496;
  --colorOceanLight: #6daedd;
  --colorLocation: #ff801f;

  --textBlack: var(--colorNightBlue);
  --textWhite: var(--colorWhite);
  --textBlue: var(--colorOcean);

  --appMaxWidth: 1280px;
  --headerHeight: 6.5em;
  --headerMobileHeight: 5em;
  --transitionFunction: cubic-bezier(.40, 0, 0, 1);

  --scrollbarWidth: 6px;

  --searchContactPaddingTop: 2em;
  --searchContactPaddingBottom: 2em;
  --searchContactPaddingTopMobile: 0.7em;
  --searchContactPaddingBottomMobile: 1.1em;
  --pageHorizontalPadding: 2.5em;
  --pageHorizontalInnerPadding: 1.5em;
  --inputHeight: 40px;
  --buttonHeight: 2.2em;
  --borderRadiusBoxes: 0.4em;

  --zIndexBg: 1;
  --zIndexMain: 5;
  --zIndexAboveMap: 1000;
  --zIndexStickies: 1010;
}

::-webkit-scrollbar {
  width: 6px;
  width: var(--scrollbarWidth);
}
::-webkit-scrollbar-thumb {
  background: #1c6bb3;
  background: var(--colorOcean);
  border-radius: 6px;
  border-radius: var(--scrollbarWidth);
  -webkit-border-radius: 6px;
  -webkit-border-radius: var(--scrollbarWidth);
}
::-webkit-scrollbar-track {
  background: #e5e8eb;
  background: var(--colorAlpine);
  border-radius: 6px;
  border-radius: var(--scrollbarWidth);
  -webkit-border-radius: 6px;
  -webkit-border-radius: var(--scrollbarWidth);
}
* {
  scrollbar-color: #1c6bb3 white;
  scrollbar-color: var(--colorOcean) var(--colorWhite);
  scrollbar-width: 6px;
  scrollbar-width: var(--scrollbarWidth);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
  background-color: var(--colorAlpineLight);
  color: #0f2942;
  color: var(--textBlack);
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #0f2942;
  color: var(--textBlack);
}

/* (BAD) FIX for safari zooming to input fields,
https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone */
input {
  font-size: 16px;
}

.button {
  height: 2.2em;
  height: var(--buttonHeight);
  border-radius: 2.2em;
  border-radius: var(--buttonHeight);
  padding: 0.5em 1em;
  border: none;
  cursor: pointer;
  background-color: #e5e8eb;
  background-color: var(--colorAlpine);
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
}

.button--blue {
  background-color: #1c6bb3;
  background-color: var(--colorOcean);
  color: white;
  color: var(--textWhite);
}

.button--blue:hover {
  background-color: #0f2942;
  background-color: var(--colorNightBlue);
}

.button--blue.button--dark-bg:hover {
  background-color: white;
  background-color: var(--colorWhite);
  color: #0f2942;
  color: var(--colorNightBlue);
}

.button--white {
  background-color: white;
  background-color: var(--colorWhite);
  color: #1c6bb3;
  color: var(--colorOcean);
}

.button--white:hover {
  background-color: #0f2942;
  background-color: var(--colorNightBlue);
  color: white;
  color: var(--colorWhite);
}

.link {
  font-weight: bold;
}

.link--menu:hover {
  color: #1c6bb3;
  color: var(--colorOcean);
}
/* <div class="loader">Loading...</div> */

.Loader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  /* prevent from being overriden by App */
  left: 0 !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Loader__spinner {
  position: relative;
  bottom: 3em;
}

/* Obtained from designer */

.Loader__spinner,
.Loader__spinner:before,
.Loader__spinner:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: animLoad 1.8s infinite ease-in-out;
  animation: animLoad 1.8s infinite ease-in-out;
}
.Loader__spinner {
  color: #018a39;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.Loader__spinner:before,
.Loader__spinner:after {
  content: '';
  position: absolute;
  top: 0;
}
.Loader__spinner:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  color:#fec916;
}
.Loader__spinner:after {
  left: 3.5em;
  color:#1c6bb3;
}
@-webkit-keyframes animLoad {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes animLoad {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.Header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: var(--zIndexStickies);
    height: var(--headerHeight);
    pointer-events: none;
}

.Header__container {
    max-width: var(--appMaxWidth);
    margin: auto;
    padding: 1.8em;
    padding-bottom: 0;
    padding-right: 2.5em;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Header__logo-container {
    display: inline-block;
    height: 100%;
    pointer-events: all;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .Header {
        height: var(--headerMobileHeight);
    }

    .Header__logo-container {
        background-color: var(--colorWhite);
        border-bottom-right-radius: 50%;
    }

}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {
    .Header__container {
        padding: 0.7em;
    }
}
.Menu {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 80%;
    z-index: var(--zIndexMain);
    pointer-events: all;
}

.Menu__container {
  padding: 1em;
  border-radius: 2em;
  background-color: var(--colorWhite);
  box-shadow: 0px 0px 8px 1px rgba(68,68,68,0.3);
}

.Menu__container > * {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.Menu ul {
    list-style: none;
    display: inline-block;
    margin-left: 0.5em;
    padding-left: 0;
    margin-top: 0;
}

.Menu ul li {
    display: inline-block;
    margin: 0 1em;
    cursor: pointer;
    vertical-align: middle;
}

.Menu ul li:last-child {
  margin-right: 0;
}

.Menu__localize-me {
    padding-right: 1.4em;
    background: transparent url(/static/media/icon-location.6ce00b8a.svg) no-repeat;
    background-position: center right;
    background-size: auto 100%;
}

.Menu__localize-me--localized {
  color: var(--colorLocation);
  background-image: url(/static/media/icon-geolocation-on-orange.6cd7c79d.svg);
}

.Menu__localize-me:hover {
  background-image: url(/static/media/icon-geolocation-on-blue.a31aeee7.svg);
}

.Menu__share-label {
  color: var(--colorAsphalt);
}

.Menu__share-button:hover svg * {
  fill: var(--colorOcean);
}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {
  .Menu__share-label, .Menu ul li.Menu__share-button {
    display: none;
  }
}

/* Style generated with https://sharingbuttons.io/ */
.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
}

.resp-sharing-button {
  border-radius: 5px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif
}

.resp-sharing-button__icon svg {
  width: 1.5em;
  vertical-align: top
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: var(--textBlack);
  fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: var(--textBlack);
  stroke: none
}
.SolutionIcon {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    /* to allow the title to be positioned absolutely.
    This is needed because otherwise it will appear below the images
    with are absolute positioned  */
    position: relative;
    margin: 2em 1em;
    width: 8em;
}

.SolutionIcon__image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 125%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.SolutionIcon__animation {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
    -webkit-transition-timing-function: var(--transitionFunction);
            transition-timing-function: var(--transitionFunction);
}

.SolutionIcon--desktop:hover .SolutionIcon__animation {
    width: 118%;
}

.SolutionIcon__image-container {
    width: 100%;
    position: relative;
    display: inline-block;
}

.SolutionIcon__image-container circle {
    fill: var(--colorAlpine);
}

.SolutionIcon__title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* allow to place the text hanging to the bottom of the main container */
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    font-weight: bold;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .SolutionIcon {
        width: 100%;
        width: 7em;
    }
}

/* NARROW_SCREEN_UPPER_1 */
@media (max-height: 720px) {
    .SolutionIcon {
        margin-top: 1.7em;
        margin-bottom: 2.7em;
    }
    .SolutionIcon__image-container {
        width: 80%;
    }
}

/* NARROW_SCREEN_UPPER_2 */
@media (max-height: 580px) {
    .SolutionIcon {
        margin-top: 1.2em;
        margin-bottom: 1.5em;
    }
    .SolutionIcon__image-container {
        width: 60%;
    }
    .SolutionIcon__title {
        font-size: 14px;
    }
}
.Home {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.Home h1 {
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 0em;
    font-size: 220%;
}

.Home__scroll-area {
    background-color: var(--colorWhite);
    overflow: auto;
    scrollbar-color: var(--colorOcean) var(--colorAlpine);
    scrollbar-width: var(--scrollbarWidth);
    padding-top: calc(var(--headerHeight) - 1em);
}

.Home__solutions {
    text-align: center;
    margin: auto;
    width: 50em;
    padding-bottom: 3em;
}

.Home__footer-search-contact {
    z-index: var(--zIndexStickies);
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .Home__solutions {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        width: 30em;
    }
}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {

    .Home__solutions {
        grid-template-columns: repeat(2, 1fr);
        width: 18em;
    }
}

/* Horrible hack to fix layout on big screen heights */
.Home.Home--such-height {
    height: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.Home.Home--such-height,
.Home.Home--such-height .Home__scroll-area {
  position: relative;
}
.Home.Home--such-height .Header,
.Home.Home--such-height .Footer__search-contact.Footer__search-contact--visible {
  position: absolute;
}


/* NARROW_SCREEN_UPPER_2 */
@media (max-height: 580px) {
    .Home h1 {
        font-size: 180%;
    }
}
/* Setup animations */
.Home .Header, 
.Home h1, 
.Home .SolutionIcon {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: var(--transitionFunction);
            transition-timing-function: var(--transitionFunction);
}
.Home--animation-start .Header, 
.Home--animation-start h1, 
.Home--animation-start .SolutionIcon {
    opacity: 1;
}

.Home--normal-height .Footer__search-contact,
.Home--normal-height .Footer__search-contact--hidden,
.Home--normal-height .FooterSearchBar,
.Home--normal-height .FooterContactBar__vous-avez-un-doute-container {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transition-property: max-height, padding-top, padding-bottom;
    transition-property: max-height, padding-top, padding-bottom;
    -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
    -webkit-transition-timing-function: var(--transitionFunction);
            transition-timing-function: var(--transitionFunction);
}
.FooterContactBar__vous-avez-un-doute-container {
    /* to hide the overflowing content */
    overflow: hidden;
}
.Home--animation-start .Footer__search-contact,
.Home--animation-start .Footer__search-contact--hidden,
.Home--animation-start .FooterSearchBar,
.Home--animation-start .FooterContactBar__vous-avez-un-doute-container {
    /* This is just a random value, trick to animate the height from 0 to auto :
    https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css */
    max-height: 50vh;
}
.Home--animation-start .FooterSearchBar {
    padding-top: var(--searchContactPaddingTop);
    padding-bottom: var(--searchContactPaddingBottom);
}

/* PHABLET_WIDTH_UPPER */
@media (min-width: 1024px) {
    .Home .FooterSearchBar {
        -webkit-transition-property: none;
        transition-property: none;
    }

}
/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .Home .Footer__search-contact,
    .Home .Footer__search-contact--hidden {
        -webkit-transition-property: none;
        transition-property: none;
    }
    .Home--animation-start .FooterSearchBar {
        padding-top: var(--searchContactPaddingTopMobile);
        padding-bottom: var(--searchContactPaddingBottomMobile);
    }
}

.Home .FooterContactBar img {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.6s;
            transition-duration: 0.6s;
    -webkit-transition-timing-function: var(--transitionFunction);
            transition-timing-function: var(--transitionFunction);
}
.Home--animation-start .FooterContactBar img {
    opacity: 1;
}

/* Animation times */    
.Home .Header, .Home h1 {
    -webkit-transition-delay: 0;
            transition-delay: 0;
    -webkit-transition-duration: 0.8s;
            transition-duration: 0.8s;
}
.Home .UnderlinedWord__line {
    -webkit-transition-delay: 1s;
            transition-delay: 1s;
}
.Home .SolutionIcon {
    -webkit-transition-duration: 0.8s;
            transition-duration: 0.8s;
}
.Home .SolutionIcon:nth-child(1) { -webkit-transition-delay: 0.7s; transition-delay: 0.7s; }
.Home .SolutionIcon:nth-child(2) { -webkit-transition-delay: 0.8s; transition-delay: 0.8s; }
.Home .SolutionIcon:nth-child(3) { -webkit-transition-delay: 0.9s; transition-delay: 0.9s; }
.Home .SolutionIcon:nth-child(4) { -webkit-transition-delay: 1.0s; transition-delay: 1.0s; }
.Home .SolutionIcon:nth-child(5) { -webkit-transition-delay: 1.1s; transition-delay: 1.1s; }
.Home .SolutionIcon:nth-child(6) { -webkit-transition-delay: 1.2s; transition-delay: 1.2s; }
.Home .SolutionIcon:nth-child(7) { -webkit-transition-delay: 1.3s; transition-delay: 1.3s; }
.Home .SolutionIcon:nth-child(8) { -webkit-transition-delay: 1.4s; transition-delay: 1.4s; }
.Home .SolutionIcon:nth-child(9) { -webkit-transition-delay: 1.5s; transition-delay: 1.5s; }
.Home .SolutionIcon:nth-child(10) { -webkit-transition-delay: 1.6s; transition-delay: 1.6s; }
.Home .SolutionIcon:nth-child(11) { -webkit-transition-delay: 1.7s; transition-delay: 1.7s; }
.Home--normal-height .Footer__search-contact, 
.Home--normal-height .Footer__search-contact--hidden, 
.Home--normal-height .FooterSearchBar,
.Home--normal-height .Footer__search-contact .FooterContactBar__vous-avez-un-doute-container 
    { -webkit-transition-delay: 1.7s; transition-delay: 1.7s; }

.Home .FooterContactBar img { -webkit-transition-delay: 1.8s; transition-delay: 1.8s; }

/* trick to allow space for the fixed footer to land
 at the bottom of the page */
.Footer__search-contact.Footer__search-contact--hidden {
    visibility: hidden;
    position: static;
    position: initial;
}

.Footer__search-contact {
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: var(--zIndexStickies);
    width: 100%;
    justify-content: center;
    /* Make mouse events go through */
    pointer-events: none;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .Footer__search-contact {
        display: block;
        position: static;
        position: initial;
        padding: 0;
    }

    .Footer__search-contact.Footer__search-contact--hidden .FooterSearchBar {
        position: static;
        position: initial;
    }

    .Footer__search-contact.Footer__search-contact--hidden .FooterContactBar {
        display: none;
    }

    .Footer__search-contact .FooterSearchBar {
        position: fixed;
        bottom: 0;
    }

    .Footer__search-contact .FooterContactBar {
        position: static;
        position: initial;
        display: block;
        text-align: center;
    }

    .Footer__search-contact .FooterSearchBar, 
    .Footer__search-contact .FooterContactBar {
        width: 100%;
        max-width: none;
        max-width: initial;
    }
}
.FooterSearchBar {
    display: inline-block;
    /* to allow the absolute positioning of the search results */
    position: relative;
    background-color: var(--colorOcean);
    /* make sure that mouse events are captured */
    pointer-events: all;
    z-index: var(--zIndexStickies);
}

.FooterSearchBar label {
    color: var(--textWhite);
    font-weight: bold;
    margin-top: 0.3em;
    margin-bottom: 0.7em;
    display: inline-block;
}

.FooterSearchBar__search-field input {
    border-radius: 1.5em;
    border: 2px solid var(--colorOcean);
    padding-left: 1em;
}

.FooterSearchBar__search-field input:hover,
.FooterSearchBar__search-field input:focus {
    border-color: var(--colorNightBlue);
}

.FooterSearchBar--no-result .FooterSearchBar__search-field input {
    border-color: var(--colorWarning);
}

.FooterSearchBar__results {
    position: absolute;
    width: 100%;
    bottom: 100%;
    left: 0;
    height: calc(100vh - 100%);
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: var(--colorOcean) no-repeat;
    background-position: center;
    background-size: 100% auto;
    color: var(--textWhite);
    /* we leave space for the border-radius of teh search results when hovered */
    padding-left: calc(var(--pageHorizontalPadding) - 1em);
    padding-right: var(--pageHorizontalPadding);
    scrollbar-color: var(--colorOcean) var(--colorAlpine);
    scrollbar-width: var(--scrollbarWidth);
    /* make sure that mouse events are captured */
    pointer-events: all;
}

.FooterSearchBar--no-result .FooterSearchBar__results {
    background-image: url(/static/media/icon-no_result.8ada0a50.svg);
}

.FooterSearchBar__results-item {
    cursor: pointer;
    padding: 0.3em 0;
    border-radius: 1em;
    padding-left: 1em;
    font-size: 32px;
}

.FooterSearchBar__results-item:hover {
    background-color: var(--colorOceanLight);
    color: var(--colorNightBlue);
}

.FooterSearchBar__results-item:first-child {
    /* There's a bug with flex justify end and scrolbars.
    This is a fix to push items to the end : https://stackoverflow.com/questions/36130760/use-justify-content-flex-end-and-to-have-vertical-scrollbar */
    margin-top: auto;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .FooterSearchBar {
        display: block;
        position: static;
        position: initial;
    }

    .FooterSearchBar label {
        font-size: 90%;
    }

}

/* NARROW_SCREEN_UPPER_2 */
@media (max-height: 580px) {
    input {
        height: 34px;
    }
}
.FooterSearchBar, .FooterContactBar {
    width: 50%;
    /* This is important to limit the width of the layout. 
    Also note that flex container must have justify content center */
    max-width: calc(var(--appMaxWidth) / 2);
    padding: 0 var(--pageHorizontalPadding);
    padding-top: var(--searchContactPaddingTop);
    padding-bottom: var(--searchContactPaddingBottom);
}


/* NARROW_SCREEN_UPPER_2 */
@media (max-height: 580px) {
    .FooterSearchBar, .FooterContactBar {
        font-size: 14px;
    }
}
.SearchField {
    position: relative;
}

.SearchField input {
    width: 100%;
    height: var(--inputHeight);
}

.SearchField input:focus {
    outline: none;
}

.SearchField__button {
    position: absolute;
    top: 50%;
    right: 0.5em;
    -webkit-transform: translateY(-38%);
            transform: translateY(-38%);
    background: none;
    border: none;
    cursor: pointer;
}

.SearchField--empty .SearchField__reset-image,
.SearchField--has-content .SearchField__search-image {
    display: none;
}

svg.SearchField__reset-image * {
    fill: var(--colorWarning);
}
.SolutionPage {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* Allow absolute positioning of backgrounds */
    position: relative;
    background-color: var(--colorWhite);
}

.SolutionPage .Header .Header__background {
    opacity: 1;
}

.SolutionPage__body {
    /* Adapt its size to take up whats left by header and footer */
    flex-grow: 1;
    flex-shrink: 100;
    overflow: hidden;
    display: flex;
    z-index: var(--zIndexMain);
}

.SolutionPage__left, .SolutionPage__right {
    width: 50%;
    height: auto;
    padding-top: var(--headerHeight);
}

.SolutionPage__left > *, .SolutionPage__right > * {
    margin-left: var(--pageHorizontalPadding);
    margin-right: var(--pageHorizontalPadding);
}

.SolutionPage__left {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.SolutionPage__left .SolutionPage__title {
    /* to allow animation for image */
    padding-right: 0;
}

.SolutionPage__right {
    background-color: var(--colorAlpine);
    /* to allow absolute positioning of elements inside */
    position: relative;
    /* the map on the right side is sized dynamically and should fill in the height */
    overflow: hidden;
}

.SolutionPage__title {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    /* Overwrite the default margin so the image can swipe in 
    from the right */
    margin-right: 0;
    /* Overwrite the default margin so we can see the underlined word */
    margin-left: 0;
    padding-left: var(--pageHorizontalPadding);
    overflow: hidden;
}

.SolutionPage__title img {
    max-width: 40%;
}

.SolutionPage__title h1 {
    max-width: 60%;
}

.SolutionPage__more-info {
    display: inline-block;
    margin: 2em 0;
}

.SolutionPage__info {
    flex-grow: 1;
    overflow: auto;
    padding-bottom: 1em;
}

.SolutionPage__info > div:last-child {
    /* Add a margin when last element of the infos */
    margin-bottom: 1em;
}

.SolutionPage__to-home {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: 80%;
}

.SolutionPage__did-you-know h2 {
    color: var(--colorOcean);
}

.SolutionPage .FooterSearchBar,
.SolutionPage .FooterContactBar,
.SolutionPage .Footer__search-contact {
    z-index: var(--zIndexStickies);
}


/* PHABLET_WIDTH_UPPER */
@media (min-width: 1025px) {
    .SolutionPage .MapShapes, .SolutionPage .MapPins {
        /* Remove margins when in desktop layout */
        margin: 0;
    }
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .SolutionPage {
        overflow: auto;
        display: block;
    }

    .SolutionPage__left, .SolutionPage__right {
        width: auto;
        background-color: var(--colorWhite);
    }

    .SolutionPage__right {
        padding-top: 0;
    }

    .SolutionPage__body {
        display: block;
        padding-bottom: 3em;
    }

    .SolutionPage .MapShapes, .SolutionPage .MapPins {
        border-radius: var(--borderRadiusBoxes);
        overflow: hidden;
    }
}

/* ANIMATION */
.SolutionPage__title img {
    position: relative;
    right: -30%;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
    -webkit-transition-timing-function: var(--transitionFunction);
            transition-timing-function: var(--transitionFunction);
}

.SolutionPage--animation-start .SolutionPage__title img {
    right: var(--pageHorizontalPadding);
}


/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .SolutionPage--animation-start .SolutionPage__title img {
        right: -1em;
    }
}
.SolutionPage--encombrant .UnderlinedWord__line { background-color: var(--colorCitron); }
.SolutionPage--verre .UnderlinedWord__line { background-color: var(--colorGardenLight); }
.SolutionPage--decheterie .UnderlinedWord__line { background-color: var(--colorOceanLight); }
.SolutionPage--bac_jaune .UnderlinedWord__line { background-color: var(--colorCitron); }
.SolutionPage--bac_gris .UnderlinedWord__line { background-color: var(--colorAlpineLight); }
.SolutionPage--sapin .UnderlinedWord__line { background-color: var(--colorGardenLight); }
.SolutionPage--electromenager .UnderlinedWord__line { background-color: var(--colorOceanLight); }
.SolutionPage--dangereux .UnderlinedWord__line { background-color: var(--colorCitron); }
.SolutionPage--textile .UnderlinedWord__line { background-color: var(--colorOceanLight); }
.SolutionPage--objets .UnderlinedWord__line { background-color: var(--colorCitron); }

.SolutionPage--decheterie .Map__legend ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0.5em;
    height: 3em;
}

.SolutionPage--decheterie .Map__legend li:first-child {
    margin-right: 1.5em;
}

.SolutionPage--decheterie .Map__legend li img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;
    height: 100%;
}

.SolutionPage--electromenager .MapInfoBox__info-box a {
    margin-top: 1em;
}

.SolutionObjets.SolutionPage {
    /* Allows the search footer to fit ok */
    padding-bottom: 0;
    margin-bottom: 0;
}

.SolutionObjets .SolutionPage__right {
    /* Because we want to be able to scroll through the solutions */
    overflow: auto;
}
.UnderlinedWord {
    display: inline-block;
    position: relative;
    /* So that the line is not overflowed */
    margin-bottom: 0.5em;
}

.UnderlinedWord__shaper {
    visibility: hidden;
}

.UnderlinedWord__text {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: var(--zIndexMain);
}

.UnderlinedWord__line {
    display: inline-block;
    width: 0.5em;
    height: 0.45em;
    border-radius: 0.5em;
    position: absolute;
    bottom: 0;
    left: -5%;
    background-color: var(--colorCitron);
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-duration: 1.2s;
            transition-duration: 1.2s;
    -webkit-transition-timing-function: var(--transitionFunction);
            transition-timing-function: var(--transitionFunction);
}

.UnderlinedWord__line--animation-start {
    width: 110%;
}
.SolutionBox {
    margin-top: 1em;
    margin-bottom: 1em;
    background-color: var(--colorWhite);
    border-radius: var(--borderRadiusBoxes);
    cursor: pointer;
}

.SolutionBox:last-child {
    margin-bottom: 2em;
}

.SolutionBox__surtitle {
    color: var(--textBlue);
    font-weight: bold;
    font-size: 85%;
}

.SolutionBox__title {
    font-size: 120%;
    font-weight: bold;
    margin: 0.3em 0;
}

.SolutionBox__header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.SolutionBox__body {
    padding: 1em;
}

.SolutionBox__footer {
    color: var(--textWhite);
    font-weight: bold;
    font-size: 85%;
    text-align: right;
    background-color: var(--colorOcean);
    padding: 1em;
    border-bottom-left-radius: var(--borderRadiusBoxes);
    border-bottom-right-radius: var(--borderRadiusBoxes);
}

.SolutionBox__footer:hover {
    background-color: var(--colorNightBlue);
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .SolutionBox {
        background-color: var(--colorAlpine);
    }

    .SolutionBox__header img {
        display: none;
    }

}

.FooterContactBar {
    background-color: var(--colorAlpine);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    z-index: var(--zIndexStickies);
}

.FooterContactBar h2 {
    margin: 0;
    margin-bottom: 0.3em;
}

.FooterContactBar img {
    height: 100%;
    margin-right: 0.5em;
}

.FooterContactBar__vous-avez-un-doute-container {
    display: flex;
    align-items: flex-end;
}

.FooterContactBar__vous-avez-un-doute {
    font-size: 80%;
}

.FooterContactBar__phone-container {
    margin-left: 0.5em;
}

.FooterContactBar__phone {
    font-size: 160%;
    font-weight: bold;
    white-space: nowrap;
}

.FooterContactBar__appel-gratuit {
    color: var(--colorAsphalt);
    font-size: 70%;
    font-style: italic;
}

/* TABLET_WIDTH_UPPER */
@media (max-width: 1280px) {

    .FooterContactBar__vous-avez-un-doute-container {
        display: inline;
        display: initial;
        align-items: initial;
    }

    .FooterContactBar__phone-container {
        margin-left: 0;
    }
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .FooterContactBar {
        display: block;
        text-align: center;
    }

    .FooterContactBar {
        width: 100%;
    }
}
.MapFooter {
    display: flex;
    justify-content: space-between;
}

.MapFooter__see-data svg,
.MapFooter__see-data span {
    display: inline-block;
    vertical-align: middle;
}

.MapFooter__see-data:hover svg * {
    fill: var(--colorWhite);
}

.MapZoomControls__plus_minus {
    display: inline-block;
    border-radius: 1em;
    background-color: var(--colorWhite);
}

.MapZoomControls__plus_minus,
.MapZoomControls__center {
    border: 3px solid var(--colorWhite);
    height: var(--buttonHeight);
}

.MapZoomControls__plus, 
.MapZoomControls__minus,
.MapZoomControls__center {
    display: inline-block;
    border-radius: 1em;
    cursor: pointer;
}

.MapZoomControls__plus, 
.MapZoomControls__minus {
    height: 100%;
}

.MapZoomControls__center {
    vertical-align: top;
    margin-right: 0.5em;
}

.MapZoomControls__minus {
    margin-right: 0.2em;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .MapZoomControls__center {
        display: none;
    }
}
.MapInfoBox__info-box {
    padding: 1em;
    color: var(--textWhite);
    background-color: var(--colorNightBlue);
    margin-bottom: 1em;
    border-radius: 1em;
    /* to allow positioning of close button */
    position: relative;
}

.MapInfoBox__expand-collapse {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
}

.MapInfoBox__info-box--error {
    background-color: var(--colorWarning);
}

.MapInfoBox__info1,
.MapInfoBox__info2,
.MapInfoBox__info3,
.MapInfoBox__info4 {
    padding: 8px 0;
}

.MapInfoBox--collapsed .MapInfoBox__info1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.MapInfoBox--collapsed .MapInfoBox__info2,
.MapInfoBox--collapsed .MapInfoBox__info3,
.MapInfoBox--collapsed .MapInfoBox__info4 {
    display: none;
}

.MapInfoBox__info1 {
    font-weight: bold;
    font-size: 20px;
}

.MapInfoBox__info2 {
    font-weight: bold;
    font-size: 14px;
}

.MapInfoBox__info3 {
    font-size: 14px;
}

.MapInfoBox__info4 {
    font-size: 12px;
}

.MapInfoBox__info-icon {
    display: inline-block;
    vertical-align: middle;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .MapInfoBox__info-box {
        margin-bottom: 0;
    }
}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {
    .MapInfoBox__expand-collapse {
        display: none;
    }
}
.MapLocalizationTools {
    --verticalPadding: 0.5em;
    padding-left: var(--pageHorizontalPadding);
    padding-right: var(--pageHorizontalPadding);
    border-bottom: 3px solid var(--colorAsphalt);
}

.MapLocalizationTools__localize-yourself {
    margin-right: 1em;
}

.MapLocalizationTools__localize-yourself label,
.MapLocalizationTools .MapAddressSearch label {
    font-size: 80%;
    font-weight: bold;
}

.MapLocalizationTools__localize-yourself button {
    width: 7em;
}

.MapLocalizationTools__localize-yourself button span {
    display: none;
}

.MapLocalizationTools__localize-yourself-button-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.MapLocalizationTools__localize-yourself--localized button {
    background-color: var(--colorLocation);
}

.MapLocalizationTools__localize-yourself--localized svg * {
    fill: var(--textBlack);
}

.MapLocalizationTools__localize-yourself--localized:hover svg * {
    fill: var(--colorWhite);
}

.MapLocalizationTools__controls {
    display: flex;
}

.MapLocalizationTools__localize-yourself button {
    height: var(--inputHeight);
    display: flex;
    justify-content: center;
    align-items: center;
}

.MapLocalizationTools .SearchField input {
    border: 2px solid var(--colorOcean);
    background-color:
    white;
    border-radius: 2em;
    padding-left: 1em;
}

.MapLocalizationTools .SearchField input:hover {
    border-color: var(--colorNightBlue);   
}

.MapLocalizationTools .SearchField--has-content input,
.MapLocalizationTools .SearchField--has-content input:hover {
    border-color: var(--colorLocation);
}

.MapLocalizationTools__les-date-ou-les-horaires {
    font-weight: bold;
    font-size: 85%;
    padding-bottom: var(--verticalPadding);
    margin-bottom: var(--verticalPadding);
    border-bottom: 1px solid var(--colorWhite);
}


/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .MapLocalizationTools {
        padding-top: 1.5em;
        padding-left: var(--pageHorizontalInnerPadding);
        padding-right: var(--pageHorizontalInnerPadding);
        background-color: var(--colorAlpine);
    }
}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {
    .MapLocalizationTools__controls {
        display: inline;
        display: initial;
    }
    .MapLocalizationTools__localize-yourself {
        margin-right: 0;
        margin-bottom: 0.8em;
    }
    .MapLocalizationTools__les-date-ou-les-horaires {
        border: none;
    }
    .MapLocalizationTools__localize-yourself button {
        width: 100%;
        text-align: left;
    }
    .MapLocalizationTools__localize-yourself-button-container {
        justify-content: space-between;
    }
    .MapLocalizationTools__localize-yourself button span, 
    .MapLocalizationTools__localize-yourself button img {
        display: inline-block;
    }
    .MapLocalizationTools__localize-yourself button img {
        float: right;
    }
}

.MapAddressSearch {
    flex-grow: 1;
    padding-bottom: calc(var(--verticalPadding) * 3);
    /* to allow absolute positioning of search results */
    position: relative;
}

.MapAddressSearch__results {
    position: absolute;
    /* +1 because must be above map legend */
    z-index: calc(var(--zIndexAboveMap) + 1);
    top: 80%;
    left: 0;
    background-color: var(--colorNightBlue);
    color: var(--colorWhite);
    width: 100%;
    border-radius: 1em;
    padding: 1em;
}

.MapAddressSearch__results-item {
    margin: 0.5em 0;
    cursor: pointer;
}

.MapAddressSearch__results-item:hover {
    color: var(--colorLocation);
}
.Map__map-container {
    position: relative;
}

.Map__footer-container {
    position: absolute;
    width: 100%;
    /* to leave space for tiles attribution */
    bottom: 20px;
    left: 0;
    z-index: var(--zIndexAboveMap);
    padding-left: var(--pageHorizontalPadding);
    padding-right: var(--pageHorizontalPadding);
}

.Map__legend {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--zIndexAboveMap);
    background-color: var(--colorWhite);
    border-radius: 2em;
    font-size: 70%;
    margin-left: var(--pageHorizontalPadding);
    margin-top: 1em;
}

.my-location-tooltip {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.my-location-tooltip::before {
    border-bottom-color: transparent;
}

.leaflet-control-container .leaflet-control-attribution {
    font-size: 8px;
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .Map__footer-container {
        margin: 0;
        margin-bottom: 1em;
        padding-left: var(--pageHorizontalInnerPadding);
        padding-right: var(--pageHorizontalInnerPadding);
    }

    .Map__legend {
        margin-left: var(--pageHorizontalInnerPadding);
    }
}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {
    .MapInfoBox__info-box {
        border-radius: 0;
    }

    .Map__footer-container {
        font-size: 80%;
    }

    .Map__legend {
        font-size: 50%;
    }
}
.App {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  max-width: var(--appMaxWidth);
  margin: auto;
  background-color: var(--colorAlpineLight);
  scrollbar-color: var(--colorOcean) var(--colorWhite);
  scrollbar-width: var(--scrollbarWidth);
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* TABLET_WIDTH_UPPER */
@media (min-width: 1280px) {
  .App > * {
      left: calc((100vw - 1280px) / 2);
  }
}
.About h2 {
  color: var(--colorOcean);
  font-size: 110%;
}

.About .SolutionPage__right {
    /* Because we want to be able to scroll through the solutions */
    overflow: auto;
}

.About .SolutionPage__info a {
    font-weight: bold;
    border-bottom: 2px var(--colorOcean) solid;
}

.About .SolutionPage__info a:hover {
    border-color: var(--textBlack);
    color: var(--colorOceanLight);
}
