.SolutionPage--encombrant .UnderlinedWord__line { background-color: var(--colorCitron); }
.SolutionPage--verre .UnderlinedWord__line { background-color: var(--colorGardenLight); }
.SolutionPage--decheterie .UnderlinedWord__line { background-color: var(--colorOceanLight); }
.SolutionPage--bac_jaune .UnderlinedWord__line { background-color: var(--colorCitron); }
.SolutionPage--bac_gris .UnderlinedWord__line { background-color: var(--colorAlpineLight); }
.SolutionPage--sapin .UnderlinedWord__line { background-color: var(--colorGardenLight); }
.SolutionPage--electromenager .UnderlinedWord__line { background-color: var(--colorOceanLight); }
.SolutionPage--dangereux .UnderlinedWord__line { background-color: var(--colorCitron); }
.SolutionPage--textile .UnderlinedWord__line { background-color: var(--colorOceanLight); }
.SolutionPage--objets .UnderlinedWord__line { background-color: var(--colorCitron); }

.SolutionPage--decheterie .Map__legend ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0.5em;
    height: 3em;
}

.SolutionPage--decheterie .Map__legend li:first-child {
    margin-right: 1.5em;
}

.SolutionPage--decheterie .Map__legend li img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;
    height: 100%;
}

.SolutionPage--electromenager .MapInfoBox__info-box a {
    margin-top: 1em;
}

.SolutionObjets.SolutionPage {
    /* Allows the search footer to fit ok */
    padding-bottom: 0;
    margin-bottom: 0;
}

.SolutionObjets .SolutionPage__right {
    /* Because we want to be able to scroll through the solutions */
    overflow: auto;
}