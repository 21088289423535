.MapLocalizationTools {
    --verticalPadding: 0.5em;
    padding-left: var(--pageHorizontalPadding);
    padding-right: var(--pageHorizontalPadding);
    border-bottom: 3px solid var(--colorAsphalt);
}

.MapLocalizationTools__localize-yourself {
    margin-right: 1em;
}

.MapLocalizationTools__localize-yourself label,
.MapLocalizationTools .MapAddressSearch label {
    font-size: 80%;
    font-weight: bold;
}

.MapLocalizationTools__localize-yourself button {
    width: 7em;
}

.MapLocalizationTools__localize-yourself button span {
    display: none;
}

.MapLocalizationTools__localize-yourself-button-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.MapLocalizationTools__localize-yourself--localized button {
    background-color: var(--colorLocation);
}

.MapLocalizationTools__localize-yourself--localized svg * {
    fill: var(--textBlack);
}

.MapLocalizationTools__localize-yourself--localized:hover svg * {
    fill: var(--colorWhite);
}

.MapLocalizationTools__controls {
    display: flex;
}

.MapLocalizationTools__localize-yourself button {
    height: var(--inputHeight);
    display: flex;
    justify-content: center;
    align-items: center;
}

.MapLocalizationTools .SearchField input {
    border: 2px solid var(--colorOcean);
    background-color:
    white;
    border-radius: 2em;
    padding-left: 1em;
}

.MapLocalizationTools .SearchField input:hover {
    border-color: var(--colorNightBlue);   
}

.MapLocalizationTools .SearchField--has-content input,
.MapLocalizationTools .SearchField--has-content input:hover {
    border-color: var(--colorLocation);
}

.MapLocalizationTools__les-date-ou-les-horaires {
    font-weight: bold;
    font-size: 85%;
    padding-bottom: var(--verticalPadding);
    margin-bottom: var(--verticalPadding);
    border-bottom: 1px solid var(--colorWhite);
}


/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {
    .MapLocalizationTools {
        padding-top: 1.5em;
        padding-left: var(--pageHorizontalInnerPadding);
        padding-right: var(--pageHorizontalInnerPadding);
        background-color: var(--colorAlpine);
    }
}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {
    .MapLocalizationTools__controls {
        display: initial;
    }
    .MapLocalizationTools__localize-yourself {
        margin-right: 0;
        margin-bottom: 0.8em;
    }
    .MapLocalizationTools__les-date-ou-les-horaires {
        border: none;
    }
    .MapLocalizationTools__localize-yourself button {
        width: 100%;
        text-align: left;
    }
    .MapLocalizationTools__localize-yourself-button-container {
        justify-content: space-between;
    }
    .MapLocalizationTools__localize-yourself button span, 
    .MapLocalizationTools__localize-yourself button img {
        display: inline-block;
    }
    .MapLocalizationTools__localize-yourself button img {
        float: right;
    }
}