.Home {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.Home h1 {
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 0em;
    font-size: 220%;
}

.Home__scroll-area {
    background-color: var(--colorWhite);
    overflow: auto;
    scrollbar-color: var(--colorOcean) var(--colorAlpine);
    scrollbar-width: var(--scrollbarWidth);
    padding-top: calc(var(--headerHeight) - 1em);
}

.Home__solutions {
    text-align: center;
    margin: auto;
    width: 50em;
    padding-bottom: 3em;
}

.Home__footer-search-contact {
    z-index: var(--zIndexStickies);
}

/* PHABLET_WIDTH_UPPER */
@media (max-width: 1024px) {

    .Home__solutions {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        width: 30em;
    }
}

/* MOBILE_WIDTH_UPPER */
@media (max-width: 640px) {

    .Home__solutions {
        grid-template-columns: repeat(2, 1fr);
        width: 18em;
    }
}

/* Horrible hack to fix layout on big screen heights */
.Home.Home--such-height {
    height: auto;
    top: 50%;
    transform: translateY(-50%);
}

.Home.Home--such-height,
.Home.Home--such-height .Home__scroll-area {
  position: relative;
}
.Home.Home--such-height .Header,
.Home.Home--such-height .Footer__search-contact.Footer__search-contact--visible {
  position: absolute;
}


/* NARROW_SCREEN_UPPER_2 */
@media (max-height: 580px) {
    .Home h1 {
        font-size: 180%;
    }
}